import {
  mdiAccountMultipleCheckOutline,
  mdiAccountOutline,
  mdiCogOutline,
  mdiFileDocumentEditOutline,
  mdiShredder,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'

export default function useTalentPoolList() {
  const talentPoolEntries = ref([])
  const choices = ref({})

  const tagsFilter = ref([])
  const gdprStatusFilter = ref([])

  const tableColumns = [
    { text: 'name', value: 'name', class: 'ps-7' },
    { text: 'tags', value: 'tags', sortable: false },
    { text: 'latestJob', value: 'latestJob', sortable: false },
    { text: 'gdprStatus', value: 'gdprStatus' },
    { text: 'validUntil', value: 'validUntil' },
    {
      text: 'actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const totalTalentPoolEntriesListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortDesc: [true],
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  })
  const overview = ref([])

  const { getTalentPoolEntries, getTalentPoolFilterChoices, getTalentPoolOverview } = useActions('talentPool', [
    'getTalentPoolEntries',
    'getTalentPoolFilterChoices',
    'getTalentPoolOverview',
  ])

  const fetchTalentPoolEntriesOverview = async () => {
    overview.value = await getTalentPoolOverview()
  }

  // fetch data
  const fetchTalentPoolEntries = async () => {
    loading.value = true
    const { data, success } = await getTalentPoolEntries({
      tags: tagsFilter.value,
      gdprStatus: gdprStatusFilter.value,
      search: searchQuery.value,
      page: options.value.page,
      pageSize: options.value.itemsPerPage,
      ordering: options.value.sortBy.map((el, index) => {
        const parsedEl = el
          .replaceAll('.', '__')
          .replace('validUntil', 'valid_until')
          .replace('gdprStatus', 'gdpr_status')
          .replace('name', 'candidate__user__last_name')
          .replace('email', 'candidate__user__email')

        return `${options.value.sortDesc[index] ? '-' : ''}${parsedEl}`
      }),
    })
    if (success) {
      if (Object.hasOwnProperty.bind(data)('results') && Object.hasOwnProperty.bind(data)('count')) {
        talentPoolEntries.value = data.results
        totalTalentPoolEntriesListTable.value = data.count
      } else {
        talentPoolEntries.value = data
        totalTalentPoolEntriesListTable.value = data.length
      }
      loading.value = false
    }
    choices.value = await getTalentPoolFilterChoices({
      tags: tagsFilter.value,
      gdprStatus: gdprStatusFilter.value,
      search: searchQuery.value,
    })
  }

  const timer = ref(null)
  const delaySearchHandler = async () => {
    clearTimeout(timer.value)
    timer.value = setTimeout(async () => {
      await fetchTalentPoolEntries()
    }, 1000)
  }

  watch([tagsFilter, gdprStatusFilter, options], async () => {
    await fetchTalentPoolEntries()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTalentPoolEntriesTotalIcon = (title) => {
    if (title === 'total') return { icon: mdiCogOutline, color: 'primary' }
    if (title === 'total valid') return { icon: mdiAccountMultipleCheckOutline, color: 'success' }
    if (title === 'total expired') return { icon: mdiFileDocumentEditOutline, color: 'error' }
    if (title === 'total anonymization requested') return { icon: mdiShredder, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  const resolveGDPRStatusColor = (title) => {
    if (title === 'valid') return { text: 'valid', color: 'success' }
    if (title === 'expired') return { text: 'expired', color: 'error' }
    if (title === 'set_for_anonymization') return { text: 'setForAnonymization', color: 'info' }
    if (title === 'anonymization_request_accepted') return { text: 'requestAccepted', color: 'success' }
    if (title === 'anonymization_request') return { text: 'anonymizationRequest', color: 'warning' }
    if (title === 'anonymization_request_declined') return { text: 'requestDeclined', color: 'error' }
    if (title === 'anonymized') return { text: 'anonymized', color: 'accent' }
    if (title === 'auto_anonymization_disabled') return { text: 'autoAnonymizationDisabled', color: 'warning' }

    return 'primary'
  }

  const resolveValidUntilDateColor = (item) => {
    if (item.expiredValidUntil) return 'error'

    return 'success'
  }

  return {
    fetchTalentPoolEntriesOverview,
    fetchTalentPoolEntries,
    resolveTalentPoolEntriesTotalIcon,
    resolveGDPRStatusColor,
    resolveValidUntilDateColor,
    delaySearchHandler,

    tableColumns,
    searchQuery,
    totalTalentPoolEntriesListTable,
    loading,
    options,
    overview,
    talentPoolEntries,
    choices,
    tagsFilter,
    gdprStatusFilter,
  }
}
