<template>
  <div id="user-list">
    <v-snackbar v-model="showSnackbarCopyNotSupported" color="error">
      {{ $t('canNotCopy') }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbarCopyNotSupported = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="showSnackbarCopiedSuccessfully" color="success">
      {{ $t('copied') }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbarCopiedSuccessfully = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <talent-pool-list-export v-model="isExportSidebarActive"></talent-pool-list-export>
    <v-row class="mb-5">
      <v-col v-for="total in overview" :key="total.title" cols="12" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.count }}
              </h2>
              <span>{{ $t(total.title) }}</span>
            </div>

            <v-avatar
              :color="resolveTalentPoolEntriesTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveTalentPoolEntriesTotalIcon(total.title).color}--text`"
            >
              <v-icon size="25" :color="resolveTalentPoolEntriesTotalIcon(total.title).color" class="rounded-0">
                {{ resolveTalentPoolEntriesTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="mb-12">
      <v-card-title v-t="'searchAndFilter'" />
      <v-row class="px-2 ma-0">
        <v-col cols="12" sm="4">
          <v-select
            v-model="tagsFilter"
            :placeholder="$t('selectTag')"
            :items="choices.tagsChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            v-model="gdprStatusFilter"
            :placeholder="$t('selectGdprStatus')"
            :items="choices.gdprStatusChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
            :item-text="(item) => $t(item.text)"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="4"> </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('search')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keydown="delaySearchHandler"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isExportSidebarActive = !isExportSidebarActive">
            <v-icon class="me-2">{{ icons.mdiExportVariant }}</v-icon>
            <span v-t="'export'" />
          </v-btn>
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isDialogVisible = !isDialogVisible">
            <v-icon class="me-2">{{ icons.mdiPlus }}</v-icon>
            <span v-t="'invite'" />
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="talentPoolEntries"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalTalentPoolEntriesListTable"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPage'),
          'items-per-page-options': [10, 50, 250],
        }"
      >
        <template #[`top`]>
          <v-dialog v-model="isDialogVisible" max-width="650px" persistent>
            <v-card class="pa-sm-10 pa-3">
              <v-card-title v-t="'invite'" class="justify-center text-h5" />
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex align-center justify-center">
                    <v-select
                      v-model="lang"
                      :label="$t('language')"
                      :placeholder="$t('language')"
                      :items="languageChoices"
                      outlined
                      dense
                      hide-details="auto"
                      @input="inviteParamChanged"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      v-model="skipInviteBy"
                      hide-details="auto"
                      class="mt-0"
                      :label="$t('dontShowWhoInvited')"
                      @change="inviteParamChanged"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="url"
                          :label="$t('url')"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          hide-details="auto"
                          @click="copyInviteUrl"
                          v-on="on"
                        >
                          <template v-slot:append-outer>
                            <v-icon
                              v-if="!linkCopied"
                              :color="copyDynamicIcon === 'copy' ? 'primary' : 'success'"
                              @click="copyInviteUrl"
                              >{{ copyDynamicIcon === 'copy' ? icons.mdiContentCopy : icons.mdiCheck }}</v-icon
                            >
                          </template>
                        </v-text-field>
                      </template>
                      <span v-t="'copyToClipboard'" />
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-4">
                    <v-btn v-t="'close'" outlined class="me-3" @click="isDialogVisible = false" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template #[`header.name`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.tags`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.latestJob`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.gdprStatus`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.validUntil`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center ms-3">
            <v-avatar class="v-avatar-light-bg primary--text primary" size="32">
              <span class="font-weight-medium">{{ avatarText(`${item.firstName} ${item.lastName}`) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-ats-talent-pool-entry-view', params: { id: item.id } }"
                class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ `${item.firstName} ${item.lastName}` }}
              </router-link>
              <span class="text-xs">{{ item.email }}</span>
            </div>
          </div>
        </template>

        <template #[`item.tags`]="{ item }">
          <span v-if="!(item.tags && item.tags.length)">-/-</span>
          <v-chip
            v-for="(tag, index) in item.tags"
            v-else
            :key="index"
            small
            label
            color="info"
            outlined
            class="font-weight-semibold ma-1"
            v-text="tag.tag"
          />
        </template>

        <template #[`item.latestJob`]="{ item }">
          <div>{{ emptyValueFormatter(item.latestJob) }}</div>
        </template>

        <template #[`item.gdprStatus`]="{ item }">
          <v-chip
            label
            small
            :color="resolveGDPRStatusColor(item.gdprStatus).color"
            :class="`v-chip-light-bg ${
              resolveGDPRStatusColor(item.gdprStatus).color
            }--text font-weight-semibold text-capitalize ma-1 mb-2`"
            >{{ $t(resolveGDPRStatusColor(item.gdprStatus).text) }}</v-chip
          >
        </template>

        <template #[`item.validUntil`]="{ item }">
          <div :class="`${resolveValidUntilDateColor(item)}--text`">
            {{ formatDateToMonthShort(item.consent.validUntil) }}
          </div>
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  :to="{ name: 'apps-ats-talent-pool-entry-view', params: { id: item.id } }"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'view'" />
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiDeleteOutline, mdiEyeOutline, mdiContentCopy, mdiCheck, mdiExportVariant } from '@mdi/js'
import { ref, computed, nextTick, onMounted } from '@vue/composition-api'

import { avatarText, emptyValueFormatter, formatDateToMonthShort } from '@core/utils/filter'

import { useActions, useState } from 'vuex-composition-helpers'

import { invoke, until, useClipboard } from '@vueuse/core'
import useTalentPoolList from './useTalentPoolList'
import TalentPoolListExport from './TalentPoolListExport.vue'

export default {
  components: {
    TalentPoolListExport,
  },
  setup(_, vm) {
    const { getInviteTalentPoolUrl } = useActions('talentPool', ['getInviteTalentPoolUrl'])
    const { recruiter } = useState('recruiter', ['recruiter'])

    const { copy, isSupported } = useClipboard()
    const copyDynamicIcon = ref('copy')

    const isDialogVisible = ref(false)
    const isExportSidebarActive = ref(false)

    const lang = ref('eng')
    const languageChoices = [
      {
        text: vm.root.$t('pl'),
        value: 'pl',
      },
      {
        text: vm.root.$t('eng'),
        value: 'eng',
      },
      {
        text: vm.root.$t('ru'),
        value: 'ru',
      },
    ]
    const skipInviteBy = ref(false)
    const url = ref('')
    const linkCopied = ref('')

    const inviteParamChanged = async () => {
      url.value = await getInviteTalentPoolUrl({
        lang: lang.value,
        invitedBy: skipInviteBy.value ? undefined : recruiter.value.id,
      })
    }

    const showSnackbarCopyNotSupported = ref(false)
    const showSnackbarCopiedSuccessfully = ref(false)

    const copyInviteUrl = async () => {
      if (!isSupported) showSnackbarCopyNotSupported.value = true
      else {
        await copy(url.value)
        copyDynamicIcon.value = 'copied'
        showSnackbarCopiedSuccessfully.value = true

        setTimeout(() => {
          copyDynamicIcon.value = 'copy'
          showSnackbarCopiedSuccessfully.value = false
        }, 5000)
      }
    }

    invoke(async () => {
      await until(computed(() => isDialogVisible.value)).toBe(true)
      nextTick(async () => {
        await inviteParamChanged()
      })
    })

    const {
      fetchTalentPoolEntriesOverview,
      fetchTalentPoolEntries,
      resolveTalentPoolEntriesTotalIcon,
      resolveGDPRStatusColor,
      resolveValidUntilDateColor,
      delaySearchHandler,

      tableColumns,
      searchQuery,
      totalTalentPoolEntriesListTable,
      loading,
      options,
      overview,
      talentPoolEntries,
      choices,
      tagsFilter,
      gdprStatusFilter,
    } = useTalentPoolList()

    onMounted(async () => {
      await fetchTalentPoolEntriesOverview()
    })

    return {
      avatarText,
      emptyValueFormatter,
      formatDateToMonthShort,
      fetchTalentPoolEntriesOverview,
      fetchTalentPoolEntries,
      resolveTalentPoolEntriesTotalIcon,
      resolveGDPRStatusColor,
      resolveValidUntilDateColor,
      delaySearchHandler,

      tableColumns,
      searchQuery,
      totalTalentPoolEntriesListTable,
      loading,
      options,
      isDialogVisible,
      isExportSidebarActive,
      overview,
      talentPoolEntries,
      choices,
      tagsFilter,
      gdprStatusFilter,

      copyDynamicIcon,

      lang,
      languageChoices,
      skipInviteBy,
      url,
      linkCopied,
      showSnackbarCopyNotSupported,
      showSnackbarCopiedSuccessfully,
      inviteParamChanged,
      copyInviteUrl,

      // icons
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiContentCopy,
        mdiCheck,
        mdiExportVariant,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
