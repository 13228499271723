<template>
  <v-navigation-drawer
    :value="isExportSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => $emit('update:is-export-sidebar-active', val)"
  >
    <div class="drawer-header d-flex align-center">
      <span v-t="'export'" class="font-weight-semibold text-base text--primary" />
      <v-spacer></v-spacer>
      <v-btn icon small @click="$emit('update:is-export-sidebar-active', false)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-alert color="info" text>
              <p v-t="'exportAlert'" class="font-weight-semibold mb-1" />
            </v-alert>
          </v-col>
        </v-row>
        <v-subheader v-t="'include'" />
        <v-row>
          <v-col v-for="(exportField, index) in exportFields" :key="`exportFields--${index}`" cols="6" md="4">
            <v-switch
              v-model="exportField.include"
              hide-details="auto"
              class="mt-0"
              :label="$t(exportField.text, { postfix: '' })"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="columnNameLanguage"
              :label="$t('columnNameLanguage')"
              :items="[
                {
                  text: $t('english'),
                  value: 'en',
                },
                {
                  text: $t('polish'),
                  value: 'pl',
                },
              ]"
              :rules="[validators.required]"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn color="primary" class="me-3" type="submit" :disabled="!valid" block :loading="loading">
              {{ $t('download') }}</v-btn
            >
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              v-t="'discard'"
              color="secondary"
              outlined
              type="reset"
              block
              @click="$emit('update:is-export-sidebar-active', false)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'

export default {
  model: {
    prop: 'isExportSidebarActive',
    event: 'update:is-export-sidebar-active',
  },
  props: {
    isExportSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const exportFields = [
      {
        text: 'firstName',
        columnName: 'firstName',
        field: 'first_name',
        include: true,
      },
      {
        text: 'lastName',
        columnName: 'lastName',
        field: 'last_name',
        include: true,
      },
      {
        text: 'email',
        columnName: 'email',
        field: 'email',
        include: true,
      },
      {
        text: 'phoneNumber',
        columnName: 'phoneNumber',
        field: 'phone',
        include: true,
      },
      {
        text: 'tags',
        columnName: 'tags',
        field: 'tags',
        include: true,
      },
      {
        text: 'latestJob',
        columnName: 'latestJob',
        field: 'latest_job',
        include: true,
      },
      {
        text: 'jobsCount',
        columnName: 'jobsCount',
        field: 'jobs_count',
        include: true,
      },
      {
        text: 'gdprStatus',
        columnName: 'gdprStatus',
        field: 'gdpr_status',
        include: true,
      },
      {
        text: 'validUntil',
        columnName: 'validUntil',
        field: 'valid_until',
        include: true,
      },
      {
        text: 'invitedBy',
        columnName: 'invitedBy',
        field: 'invited_by',
        include: true,
      },
    ]

    const vm = getCurrentInstance().proxy

    const { exportTalentPoolEntries } = useActions('talentPool', ['exportTalentPoolEntries'])

    const columnNameLanguage = ref(vm.$i18n.locale)

    const loading = ref(false)
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const onSubmit = async () => {
      if (valid.value) {
        loading.value = true
        const fields = []
        const columnNames = []
        exportFields.forEach((field) => {
          if (field.include) {
            fields.push(field.field)
            if ('columnName' in field)
              columnNames.push(vm.$t(field.columnName, columnNameLanguage.value, { postfix: '' }))
          }
        })

        const { data, fileName, success } = await exportTalentPoolEntries({
          fields,
          column_names: columnNames,
        })
        if (success) {
          const anchor = document.createElement('a')
          anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`
          anchor.target = '_blank'
          anchor.download = fileName
          anchor.click()
          anchor.remove()
        }

        loading.value = false
        emit('update:is-export-sidebar-active', false)
      } else {
        validate()
      }
    }

    return {
      onSubmit,

      exportFields,
      columnNameLanguage,
      form,
      loading,
      valid,
      validators: { required },

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
